import * as React from "react"
import { Link } from "gatsby"
const Banner = () => {
  return (
    <section className="banner-area relative" id="home">
      <div className="overlay overlay-bg"></div>
      <div className="container h-100">
        <div className="row h-100 fullscreen d-flex justify-content-center align-items-center">
          <div className="banner-content col-lg-9 col-md-12 justify-content-center">
            <h1>
              Madhuram <br />
              Interiors
            </h1>
            <p className="text-white mx-auto">
              One stop solution for all your home or corporate needs and
              maintenance.
            </p>
            {/* <Link to="/services/" className="primary-btn header-btn text-uppercase mt-10">Get Started</Link> */}
          </div>
        </div>
      </div>
    </section>
  )
}
export default Banner
